
import request from '../request'

// export const login = (params) => {
//     return axios.post(`/api/blade-party-admin/dict-biz/dictionary${params.param}`, params)
//         .then(res => res)
//         .catch(err => { console.log(err) });
// };
const auth = 'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI5Mzg5NDIiLCJyb2xlX25hbWUiOiIiLCJ1c2VyX2lkIjoiMTI0ODE3MzAzMjUyMjkxNTg0MiIsInVzZXJfbmFtZSI6IjE3NzMwMjcyOTYyIiwibmlja19uYW1lIjoi6ZmI6YeR5a6dIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMjQxNjI1OTU4ODY0MTYyODE4IiwiYWNjb3VudCI6IjE3NzMwMjcyOTYyIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE1OTQ2MzQwNzgsIm5iZiI6MTU4NzQzNDA3OH0.5awy-liXOBX3HQqH_IvCrJ61TNp1K-sMKKDjAuUETdo';
// 待领取任务
export const getTaskListByCatch = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/list/acceptable`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 已完成任务
export const getTaskListByEnd = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/list/completed`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 进行中任务
export const getTaskListByRunning = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/list/going`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};

// 领取任务
export const getTaskPost = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/accept?missionId=${params.missionId}`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};

// 任务详情
export const taskDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/detail`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};

// 任务审核详情
export const approveMsgDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/summary/detail?auditId=${params.auditId}`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 任务总结
export const taskSummery = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/summarize?content=${params.content}&imgUrl=${params.imgUrl}&missionId=${params.missionId}`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 审批列表
export const approveList = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/summary/list`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 审批详情

export const taskApproveDetail = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/summary/detail?auditId=${params.auditId}`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 任务总结审批
export const taskSummeryApprove = (params) => {
    return request({
        url: `/blade-party-admin/min/errand/mission-infos/summary/audit?score=${params.score}&opinion=${params.opinion}&auditId=${params.auditId}`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};