<template >
  <div class="containter">
    <div class="headImgArea">
      <!-- <img :src="detail.titleImg" style="width:100%;height:100%" /> -->
      <van-image fit="contain" :src="detail.titleImg" style="width:100%;height:100%">
        <template v-slot:error>
          <img :src="errorImg" style="width:100%;height:100%;" />
        </template>
      </van-image>
    </div>
    <div class="tabMsgArea">
      <div class="mainTitleLine">
        <span class="marginLeft10">{{detail.sname}}</span>
      </div>
      <!-- <div class="mainBottomLine">
        <div class="leftMainArea">
          <div class="leftMainAreaFont2">报名人数：</div>
          <span class="leftMainAreaFontNumNow">{{detail.nowNum}}</span>
          <span class="leftMainAreaFontNum">/</span>
          <span class="leftMainAreaFontNum">{{detail.totalNum}}</span>
        </div>
        <div class="rightMainArea">
          <img :src="greyLove" class="leftMainAreaImg" />
          <div class="leftMainAreaFont">{{detail.zan}}</div>
          <img :src="eyeIcon" class="leftMainEyeImg" />
          <div class="leftMainAreaFont">{{detail.eyeNum}}</div>
        </div>
      </div>-->
      <div class="baseLine"></div>
      <div class="msgTab">
        <div class="msgTabLine">
          <span class="marginLeft10">任务类型：{{detail.typeName}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">主办单位：{{detail.branchName}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">任务地点：{{detail.address}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">活动时间：{{detail.startTime+'至'+detail.endTime}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">发布人：{{detail.publishPersonName}}</span>
        </div>
      </div>
      <div class="height10"></div>
    </div>
    <div class="tabMsgArea marginTop10">
      <div class="mainTitleLine">
        <span class="marginLeft10">任务信息</span>
      </div>
      <div class="rich-text">{{detail.description}}</div>
      <div class="contentLine">
        <span class="marginLeft10">参与人：{{detail.executorName}}</span>
      </div>
      <!-- <div class="mainTitleLine">
        <span class="marginLeft10">任务总结</span>
      </div>-->
      <div class="fontContent">任务总结：{{detail.summary}}</div>
      <div class="commentsImgLine" v-if="detail.summaryPic&&detail.summaryPic.length>0">
        <div class="commentsImgItem" v-for="imgItem in detail.summaryPic.split(',')" :key="imgItem">
          <!-- <img :src="imgItem" style="width:100%;height:100%" /> -->
          <van-image fit="contain" :src="imgItem" style="width:100%;height:100%" />
        </div>
      </div>
      <div class="height10"></div>
    </div>
    <div class="tabMsgArea marginTop10" v-if="detail.status===3">
      <!-- <div class="mainTitleLine">
        <span class="marginLeft10">任务总结</span>
      </div>
      <div class="rich-text" v-html="detail.content"></div>
      <div class="baseLine"></div>-->
      <div class="mainTitleLine">
        <span class="marginLeft10">审批信息</span>
      </div>
      <div class="taskCatchLine">
        <span class="approveTitle">审批人：</span>
        <span class="font12">{{detail.auditPersonName}}</span>
      </div>
      <div class="taskCatchLine">
        <span class="approveTitle">得分：</span>
        <span class="font12">{{detail.score}}</span>
      </div>
      <div class="taskCatchLine">
        <span class="approveTitle">评语：</span>
        <span class="font12">{{detail.opinion}}</span>
      </div>
      <div class="height15"></div>
    </div>
    <div class="height82" v-if="detail.status===2"></div>
    <!-- <div class="bottomZanLine" v-if="detail.taskStatus===2">
      <div class="bottomButtonTemp yellowButton">提交任务总结</div>
    </div>-->
    <div class="bottomZanLine" v-if="detail.status===2">
      <div class="bottomButtonTemp redButton" @click="approve">审批</div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { taskApproveDetail, approveMsgDetail } from '../../api/task';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'ActivityDetail',

  data() {
    return {
      // 任务的四种状态 1-待领取 2-进行中 3-已完成 4-已终止
      // 审核的两种状态 1-待审核 2-已审核
      greyLove: require('../activity/imgs/greyHeart.png'),
      eyeIcon: require('../activity/imgs/eyeIcon.png'),
      bigRedHeart: require('../activity/imgs/bigRedHeart.png'),
      bigGreyHeart: require('../activity/imgs/bigGreyHeart.png'),
      comments: require('../activity/imgs/comments.png'),
      errorImg: require('../../assets/img/list.png'),
      errorPicAvatar: require('../../assets/img/list-compre.png'),
      detail: {},
      commentsList: []
    };
  },
  watch: {},
  created() {
    dd.biz.navigation.setTitle({
      title: '任务审批详情', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
    this.getRecords();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const { detailId } = this.$route.query;
      // 利用id查询detail
      taskApproveDetail({
        auditId: detailId
      })
        .then(res => {
          if (res.success) {
            const detail = res.data || {};
            detail.startTime = detail.startTime
              ? detail.startTime.slice(0, 16)
              : '';
            detail.endTime = detail.endTime ? detail.endTime.slice(0, 16) : '';
            this_.detail = detail;
            // 如果活动已经审核，则调取活动信息
            // if(detail.status===3){
            //   this_.approveMsg(detail.id);
            // }
            this_.approveMsg(detail.id);
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    approveMsg(id) {
      const this_ = this;
      approveMsgDetail({
        auditId: id
      })
        .then(res => {
          if (res.success) {
            const detail = res.data || {};
            this_.summeryDetail = detail;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    approve() {
      const { id, totalScore } = this.detail;
      this.$router.push({
        name: 'taskApproveSubmit',
        query: { id: id, totalScore: totalScore }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headImgArea {
  width: 100%;
  height: 160 * $px;
}
.mainTitleLine {
  width: 100%;
  height: 46 * $px;
  line-height: 46 * $px;
  color: #222222;
  font-size: 16 * $px;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.mainBottomLine {
  width: 100%;
  height: 44 * $px;
}
.leftMainArea {
  width: 30%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rightMainArea {
  width: 70%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftMainAreaImg {
  width: 16 * $px;
  height: 14.5 * $px;
  margin-left: 12 * $px;
}
.leftMainAreaFont {
  width: 30 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainAreaFont2 {
  width: 62 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 10 * $px;
}
.leftMainEyeImg {
  width: 16 * $px;
  height: 12 * $px;
}
.leftMainAreaFontNum {
  font-size: 12 * $px;
  color: #555555;
}
.leftMainAreaFontNumNow {
  color: #2797ff;
  font-size: 16 * $px;
}
.msgTab {
  width: 100%;
  height: 130 * $px;
}
.baseLine {
  width: 351 * $px;
  height: 0.6 * $px;
  margin-left: 12 * $px;
  background-color: #eeeeee;
}
.msgTabLine {
  width: 100%;
  height: 20%;
  font-size: 14 * $px;
  color: #555555;
  display: flex;
  align-items: center;
}
.tabMsgArea {
  background-color: #ffffff;
}
.height10 {
  width: 100%;
  height: 10 * $px;
}
.height15 {
  width: 100%;
  height: 15 * $px;
}
.marginTop10 {
  margin-top: 10 * $px;
}
.departArea {
  width: 100%;
  height: 60 * $px;
  margin: 10 * $px 0;
  background-color: #ffffff;
}
.avtorArea {
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 20 * $px;
  overflow: hidden;
  background-color: grey;
  margin: 10 * $px;
  float: left;
}
.listItemTitleLineContent {
  width: 280 * $px;
  height: 100%;
  float: left;
}
.listItemTitleLineContent1 {
  font-size: 14 * $px;
  color: #555555;
  margin: 12 * $px 10 * $px 0 * $px 10 * $px;
}
.listItemTitleLineContent2 {
  font-size: 11 * $px;
  color: #999999;
  margin-left: 10 * $px;
}
.rich-text {
  padding: 10 * $px;
  margin-bottom: 10 * $px;
  font-size: 14 * $px;
  line-height: 200%;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}
.commentsTitle {
  font-size: 14 * $px;
  color: #222222;
  margin-bottom: 5 * $px;
  margin-left: 12.5 * $px;
}
.commentsItem {
  margin: 15 * $px 12 * $px 0 12 * $px;
  overflow: auto;
  border-bottom: #eeeeee 0.5 * $px solid;
}
.commentsAvtorArea {
  width: 33 * $px;
  float: left;
  display: flex;
  justify-content: center;
}
.commentsAvtor {
  width: 33 * $px;
  height: 33 * $px;
  background-color: grey;
  overflow: hidden;
  border-radius: 16.5 * $px;
}
.commentsContentArea {
  margin-left: 8 * $px;
  width: 310 * $px;
  float: left;
}
.commetUserAndDate {
  height: 15 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontName {
  color: #555555;
  font-size: 14 * $px;
}
.fontDate {
  color: #999999;
  font-size: 11 * $px;
}
.fontContent {
  margin: 8 * $px 10 * $px 0 10 * $px;
  color: #555555;
  font-size: 14 * $px;
}
.commentsBottomLine {
  width: 100%;
  height: 0.5 * $px;
  background-color: #eeeeee;
  margin-top: 15 * $px;
}
.commentsImgLine {
  margin: 0 10 * $px;
  width: 355 * $px;
  word-break: break-all;
  overflow: auto;
}
.commentsImgItem {
  height: 70 * $px;
  width: 70 * $px;
  margin-top: 9 * $px;
  margin-right: 10 * $px;
  background-color: #dddddd;
  overflow: hidden;
  float: left;
}
.marginBottom60 {
  margin-bottom: 60 * $px;
}
.bottomZanLine {
  position: fixed;
  bottom: 0 * $px;
  left: 0 * $px;
  width: 100%;
  height: 74 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.zanColumn {
  width: 26 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20 * $px;
}
.imgRedHeart {
  width: 22 * $px;
  height: 19 * $px;
  margin-top: 10 * $px;
}
.imgComments {
  width: 19 * $px;
  height: 19 * $px;
  margin-top: 10 * $px;
}
.zanfont {
  margin-top: 2 * $px;
  font-size: 12 * $px;
  color: #555555;
}
.bottomButtonArea {
  width: 230 * $px;
  height: 100%;
  margin-left: 34 * $px;
  display: flex;
  align-items: center;
}
.bottomButton {
  width: 230 * $px;
  height: 33 * $px;
  border-radius: 16.5 * $px;
  text-align: center;
  line-height: 33 * $px;
  font-size: 14 * $px;
}
.baomingButton {
  background-color: #ff192f;
  color: #ffffff;
}
.haveBaomingButton {
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  color: #ff192f;
}
.activityEnd {
  background-color: #eeeeee;
  color: #999999;
}
.taskCatch {
  width: 100%;
  height: 115.5 * $px;
  margin-top: 10 * $px;
  background-color: #ffffff;
}
.taskCatchTitle {
  width: 100%;
  height: 43 * $px;
  line-height: 43 * $px;
  color: #222222;
  font-size: 16 * $px;
}
.taskCatchLine {
  height: 15 * $px;
  margin-bottom: 9 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14 * $px;
  color: #555555;
}
.redline {
  margin-left: 13.5 * $px;
  height: 8 * $px;
  border-radius: 4 * $px;
  background-color: #ff192f;
}
.yellowline {
  margin-left: 13.5 * $px;
  height: 8 * $px;
  border-radius: 4 * $px;
  background-color: #ffc600;
}
.greyline {
  margin-left: 13.5 * $px;
  height: 8 * $px;
  border-radius: 4 * $px;
  background-color: #dddddd;
}
.contentFont {
  color: #222222;
  font-size: 12 * $px;
  margin-left: 5 * $px;
}
.bottomButtonTemp {
  height: 44 * $px;
  width: 345 * $px;
  border-radius: 22 * $px;
  font-size: 16 * $px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yellowButton {
  background-color: #ffc600;
}
.redButton {
  background-color: #ff192f;
}
.height82 {
  width: 100%;
  height: 82 * $px;
}
.approveTitle {
  width: 68 * $px;
  display: flex;
  justify-content: flex-end;
}
.font12 {
  color: #222222;
  font-size: 14 * $px;
}
.contentLine {
  margin-top: 10 * $px;
  height: 15 * $px;
  width: 100%;
  font-size: 14 * $px;
  color: #555555;
}
.detailLine {
  width: 100%;
  font-size: 14 * $px;
  color: #555555;
}
.picLine {
  width: 100%;
}
</style>



